@font-face {
  font-family: "Chunkfive";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/chunk/ChunkFive-Regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/chunk/ChunkFive-Regular.otf") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/chunk/ChunkFive-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/chunk/ChunkFive-Regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/chunk/ChunkFive-Regular.ttf")
      format("truetype");
}
